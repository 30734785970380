import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home/Home';
import Auth from './pages/Login/Auth';
import RegistrationForm from './components/RegistrationForm';
import { baseUrl } from './utils/constant_api';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ConfirmMail from './components/ConfirmMail';
import EmployersPage from './pages/EmployersPage/EmployersPage';
import CoiffurePage from './pages/CoiffurePage/CoiffurePage';
import OfferPage from './pages/OfferPage/OfferPage';
import ProfilPage from './components/ProfilPage/ProfilPage';
import Voyages from './pages/Voyages/Voyages';
import AdminLayout from './pages/AdminDashbord/AdminLayout';
import AdmiHome from './pages/AdminDashbord/AdmiHome';
import CorporateList from './pages/AdminDashbord/CorporateList';
import MetiersList from './components/AdminDashboard/MetiersList';
import EmployersClient from './pages/EmployersPage/EmployersClient';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import CoiffureForm from './pages/CoiffurePage/CoiffureForm';
import ResultatsVoyage from './pages/Voyages/ResultatsVoyage';
import { CorporateClientRoute } from './components/PrivateRoutes/CorporateClientRoute';
import AdminTravelPage from './pages/Voyages/AdminTravelPage';
import Pack from './pages/PaymentPage/Pack';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import OfferDetails from './pages/OfferDetails/OfferDetails';
import UserRegister from './pages/UserRegister';
import ServicesRegister from './components/Services/ServicesRegister';
import TechnicienRegister from './components/Services/TechnicienRegister';
import AgencyRegister from './pages/AgencyRegister';
import ClientTravelPage from './pages/ClientTravelPage';
import Servicefilter from './pages/Servicefilter';
import CoiffureProfile from './pages/CoiffureProfile';
import AdminMetiers from './pages/AdminDashbord/AdminMetiers';
import MecanicienPage from './pages/MecanicienPage';
import EmployersMaison from './pages/EmployersMaison';
import MaisonFilter from './pages/MaisonFilter';
import AdminTechnicien from './pages/AdminDashbord/AdminTechnicien';
import ClientTravelDetails from './pages/ClientTravelDetails';
import UpdateProfile from './pages/UpdateProfile';
import CreatePlans from './pages/CreatePlans';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Partenaires from './pages/Partenaires';
import ResetPassword from './pages/ResetPassword';
import VerifyOtp from './pages/VerifyOtp';
import Contacts from './pages/Contacts';
// import ClientLayout from './components/ClientLayout'; 
// import ClientDashboard from './pages/clientDashboard/ClientDashboard';
// import ClientHome from './pages/clientDashboard/ClientHome';
// import Candidate from './pages/clientDashboard/Candidate';
// import CorporateLayout from './components/CorporateDashboard/CorporateLayout';
// import CorporateDashboard from './pages/CorporateDashboard/CorporateDashboard';
// import CreateOffer from './components/CorporateDashboard/CreateOffer';
// import ClientPrivateRoute from './components/PrivateRoutes/ClientPrivateRoute';
// import ClientServicesPage from './pages/ServicesPage/ClientServicesPage';
// import OfferList from './pages/AdminDashbord/OfferList';
// import Candidatures from './pages/AdminDashbord/Candidatures';
// import CorporateProfil from './pages/AdminDashbord/CorporateProfil';



function App() {
  const [metiers, setMetiers] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const [coiffures, setCoiffures] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [partenaires, setPartenaires] = useState([]);
  const [plans, setPlans] = useState([]);
  

  const getMe = async () => {
    const token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      await axios.get(`${baseUrl}/auth/me`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ).then((response) => {
        if (response.status === 200) {
          setAuthUser(response.data);
        } else {
          localStorage.removeItem('token');
        }
      }).catch((error) => {
        localStorage.removeItem('token');
        window.location.href = "/";
      });
    }
  };

  const populateMetiers = async () => {
    await axios.get(`${baseUrl}/metiers`)
      .then((res) => {
        setMetiers(res.data)
      })
  };

  const getCoiffures = async () => {
    await axios.get(`${baseUrl}/coiffures`)
    .then((res) => {
      setCoiffures(res.data)
    })
  };
  const getProfiles = async () => {
    await axios.get(`${baseUrl}/profiles`)
    .then((res) => {
      setProfiles(res.data)
    });
  };

  const getPartenaires = async () => {
    const response = await axios.get(`${baseUrl}/partenaires`);
      setPartenaires(response.data);
  };
  

  const getPlans = async () => {
    axios.get(`${baseUrl}/plans`)
    .then((res) => {
        setPlans(res.data);
    })
  }
  

  useEffect (() => {
    getMe();
    populateMetiers();
    getCoiffures();
    getProfiles();
    getPartenaires();
    getPlans();
  }, []);


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='' element={<Layout authUser = {authUser}/>}>
            <Route path='/' element={<Home metiers ={metiers} />} />
            <Route path='login' element={<Auth />} />
            <Route path='politique-confidentialite' element={<PrivacyPolicy />} />
            <Route path='recruteur-register' element={<RegistrationForm metiers ={metiers} />} />
            <Route path='confirm' element={<ConfirmMail/>}/>
            <Route path ='coiffure' element={<CoiffurePage/>}/>
            <Route path ='coiffure/:coiffureName' element={<CoiffureProfile profiles={profiles}/>}/>
            <Route path ='mecanicien' element={<MecanicienPage profiles={profiles}/>}/>
            <Route path='offre' element={<OfferPage authUser = {authUser}/>}/>
            <Route path='travel' element={<Voyages/>}/>
            <Route path='voyage/:ville_depart/:ville_arrive/:id' element={<ResultatsVoyage/>}/>
            <Route path='emploi/detail-offre/:id' element={<OfferDetails/>}/>
            <Route path='services' element={<ServicesPage metiers ={metiers} profiles={profiles}/>}/>
            <Route path='services/:metierName' element={<Servicefilter metiers ={metiers} profiles={profiles}/>}/>
            <Route path='employer-maison/:metierName' element={<MaisonFilter metiers ={metiers} profiles={profiles}/>}/>
            <Route path='employers-maison' element={<EmployersMaison metiers ={metiers} profiles={profiles}/>}/>
            <Route path ='employers' element={<EmployersClient authUser = {authUser} metiers ={metiers} profiles={profiles} />}/>
            <Route path='create-travel' element = {<AdminTravelPage metiers ={metiers} />} />
            <Route path='agence-travel' element = {<ClientTravelPage authUser = {authUser}/>} />
            <Route path='agence-travel/:id' element = {<ClientTravelDetails authUser = {authUser}/>} />
            <Route path='partenaires' element = {<Partenaires partenaires = {partenaires}/>} />
            
            <Route path='reset-password' element = {<ResetPassword/>} />
            <Route path='otp-confirmation' element = {<VerifyOtp/>} />
            <Route path='contacts' element = {<Contacts/>} />

            <Route path='update_profil' element = {<UpdateProfile authUser = {authUser} metiers ={metiers} />} />

            <Route path='create-travel-agency' element = {<AgencyRegister metiers ={metiers} />} />
            <Route path='complete-profil' element = {<UserRegister metiers ={metiers} />} />
            <Route path='service-register' element = {<ServicesRegister metiers ={metiers} />} />
            <Route path='technicien-register' element = {<TechnicienRegister metiers ={metiers} />} />
            <Route path='profil-page' element = {<ProfilPage authUser = {authUser} />} />
            <Route path='mon-plan-premium' element= {<Pack authUser = {authUser} plans={plans}/>}/>
            <Route path='mon-plan-premium/plan/:nom/:id' element= {<PaymentPage authUser = {authUser}/>}/>
         </Route>

          {/* <Route element = {<ClientPrivateRoute/>}>
              <Route path='client-monespace' element={<ClientDashboard  authUser = {authUser}/>}>
                <Route path='' element= {<ClientHome authUser = {authUser}/>}/>
                <Route path='candidate' element = {<Candidate uthUser = {authUser}/>} />
                <Route path='services' element={<ClientServicesPage authUser = {authUser} metiers ={metiers}/>}/>
                <Route path='mon-plan-premium' element= {<Pack authUser = {authUser}/>}/>
                <Route path='mon-plan-premium/plan/:nom/:id' element= {<PaymentPage authUser = {authUser}/>}/>
                <Route path='emploi/detail-offre/:id' element={<OfferDetails/>}/>
              </Route>
          </Route> */}
          {/* <Route element = {<CorporateClientRoute/>}>
            <Route path='corporate-space' element={<CorporateLayout  />}>
              <Route path='' element= {<CorporateDashboard authUser = {authUser}/>}/>
              <Route path='create-offer' element= {<CreateOffer metiers ={metiers}/>}/>
              <Route path='my-offer-list' element= {<OfferList metiers ={metiers} authUser = {authUser}/>}/>
              <Route path='candidature-list' element= {<Candidatures metiers ={metiers}/>}/>
              <Route path='my-profile' element= {<CorporateProfil authUser = {authUser}/>}/>
              <Route path='mon-plan-premium' element= {<Pack authUser = {authUser}/>}/>
              <Route path='mon-plan-premium/plan/:nom/:id' element= {<PaymentPage authUser = {authUser}/>}/>
            </Route>
          </Route> */}

          <Route element = {<CorporateClientRoute/>}>
            <Route path='admin' element={<AdminLayout  authUser = {authUser}/>}>
              <Route path='' element= {<AdmiHome/>}/>
              <Route path ='employers' element={<EmployersPage profiles={profiles} metiers ={metiers}/>}/>
              <Route path ='technicien' element={<AdminTechnicien profiles={profiles} metiers ={metiers}/>}/>
              <Route path ='corporate' element={<CorporateList partenaires={partenaires} metiers ={metiers}/>}/>
              <Route path ='metiers-list' element={<MetiersList metiers ={metiers}/>}/>
              {/* <Route path='admin-coiffure' element = {<AdminCoiffurePage coiffures ={coiffures} />} /> */}
              <Route path='create-coiffure' element = {<CoiffureForm coiffures ={coiffures} />} />
              <Route path='admin-travel' element = {<AdminTravelPage />} />
              <Route path='metiers' element = {<AdminMetiers metiers ={metiers} />} />
              <Route path='create-plan' element = {<CreatePlans metiers ={metiers} />} />
              {/* <Route path ='home-workers' element={<HomeWorkers metiers ={metiers}/>}/> */}
              {/* <Route path='candidate' element = {<Candidate metiers ={metiers} />} /> */}
            </Route>
          </Route>



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

const Category = ({categories}) => {
  return (
    <div className="py-8 mt-12 px-4">
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* first col */}
        
          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Chercheurs d'emplois</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to ='employers' >parcourir</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Offreurs d'emplois</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to ='corporate' >parcourir</Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div  className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Employers de maisons</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to ='home-workers' >Abonnements</Link>
                </div>
              </div>
            </div>
          </div> */}

          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div  className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Coiffures</p>
                <div>
                  <a className="px-4 py-2 border rounded-xl bg-blue-600" href = "admin/create-coiffure">parcourir</a>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div  className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Techniciens et Prestataires</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to='technicien' >parcourir</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div  className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Agences de voyages</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to = 'admin-travel'>parcourir</Link>

                </div>
              </div>
            </div>
          </div>

          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div  className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">plans d'Abonnements</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to = 'create-plan'>parcourir</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-5 bg-gradient-to-br from-black/90 to-black/70 text-white rounded-3xl relative  flex p-4" >
            <div  className="w-full">
              <div className="p-2">
                <p className="text-xl font-semibold mx-2 mb-4">Domaines</p>
                <div>
                  <Link className="px-4 py-2 border rounded-xl bg-blue-600" to='metiers'>parcourir</Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Category;

import React, { useRef, useState } from "react";
import "../../pages/profilForm.css";
import "../../pages/ServicesPage/clienrServices.css";
import {
  TextField,
  Grid,
  Autocomplete,
  Stack,
  LinearProgress,
  Button,
  Typography,
  Container,
  CircularProgress,
  Dialog,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { Alert } from "@mui/material";
import { FaCheck, FaClock, FaEye, FaEyeSlash } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";

const steps = [
  "A propos de vous",
  "A propos de votre entreprise",
  "Informations de connexion",
];
const ServicesRegister = ({ metiers }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isPasswordHidden, setPasswordHidden] = useState(true);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [quatier, setQuatier] = useState("");
  const [pays, setPays] = useState("Cameroun");
  const [ville, setVille] = useState("");
  const [biographie, setBiographie] = useState("");
  const [message, setMessage] = useState("");
  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const totalSteps = 3;

  const [isOpen, setIsOpen] = useState(false);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, i) => currentYear - i
  );

  const onFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setMyFiles(files);

    if (files.length === 0) return;

    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
  };
  const deleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const Close = () => {
    setIsOpen(false);
  };
  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue_2, setSelectedValue_2] = useState(null);
  const [selectedValue_3, setSelectedValue_3] = useState(null);

  const options = metiers?.map((option, i) => {
    const firstLetter = option?.titre[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const validateStep1 = () => {
    return lastName.length > 0 && ville.length > 0 && phone.length > 0;
  };

  const handleNext = () => {
    if (validateStep1()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setCurrentStep(currentStep + 1);
    } else {
      setIsOpen(true);
      setMessage("veuillez saisir votre Nom, votre Numéro de teléphone et la ville");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(currentStep - 1);
  };
  const validateForm = () => {
    return (
      password.length > 0
    );
  };

  const goToLogin = () => {
    window.location.href = "/login";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateNaissance = `${day}-${month}-${year}`;
    const metier_id = selectedValue?.id;
    const metier_id_2 = selectedValue_2?.id;
    const metier_id_3 = selectedValue_3?.id;

    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("metier_id", metier_id);
      formData.append("metier_id_2", metier_id_2);
      formData.append("metier_id_3", metier_id_3);
      formData.append("biographie", biographie);
      formData.append("pays", pays);
      formData.append("ville", ville);
      formData.append("quatier", quatier);
      formData.append("lastName", lastName);
      formData.append("firstName", firstName);
      formData.append("datenais", dateNaissance);
      formData.append("phoneNumber", phone);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("isPrestataire", true);
      myFiles.forEach((file) => formData.append("images[]", file));

      axios
        .post(`${baseUrl}/employe/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage("Votre comptre a été créé avec success");
            setSuccess(true);
            setLoading(false);
            setIsOpen(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error.response.data.errors);
          setMessage(error.response.data.errors);
          setIsOpen(true);
        });
    } else {
      setIsOpen(true);
      setMessage("veuillez saisir votre mot de passe");
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="mb-2 md:mx-auto sm:text-center lg:max-w-2xl md:mb-4 p-2 ">
              <h2 className="text-lg font-bold text-blue-600">
                S'ENREGISTRER SUR EMPLOI MONDE
              </h2>
              <span className="text-BASE font-semibold text-gray-500">
                PRESTATAIRE DE SERVICES{" "}
              </span>
            </div>
            <label className="mb-4 font-bold text-xl flex justify-center">
              À propos de vous
            </label>
            <div className="mb-3 mt-2">
              <div className="w-full lg:flex gap-2 md:px-4">
                <TextField
                  required
                  label="Nom"
                  className="mb-2 w-full"
                  name="name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
                <TextField
                  required
                  label="Prénom"
                  className="mb-2 w-full"
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>

              <label htmlFor="" className="flex text-left mx-2 text-lg">
                date de naissance
              </label>
              <div className="w-full flex py-2 gap-1">
                <select
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3"
                  id="day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  required
                >
                  <option value="">Jour</option>
                  {days.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
                <select
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3"
                  id="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  required
                >
                  <option value="">Mois</option>
                  <option value="Janvier">Janvier</option>
                  <option value="Fevrier">Février</option>
                  <option value="Mars">Mars</option>
                  <option value="Avril">Avril</option>
                  <option value="Mai">Mai</option>
                  <option value="Juin">Juin</option>
                  <option value="Juillet">Juillet</option>
                  <option value="Aout">Aout</option>
                  <option value="Septembre">Septembre</option>
                  <option value="Octobre">Octobre</option>
                  <option value="Novembre">Novembre</option>
                  <option value="Decembre">Decembre</option>
                </select>
                <select
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3"
                  id="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  required
                >
                  <option value="">année</option>
                  {years.map((y) => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full gap-2 md:px-4">
                <label className="ml-1 flex text-xs">Adresse Email</label>
                <div className="">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Adresse Email"
                    className="w-full pr-12 pl-3 mb-3 py-3 text-gray-600 bg-transparent outline-none border rounded-lg"
                  />
                </div>
              </div>

              <div className="w-full md:px-4 lg:flex gap-2">
                <TextField
                  label="Numéro de télephone"
                  className="mb-2 w-full "
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  required
                />
                <TextField
                  className="mb-2 w-full "
                  label="Pays"
                  onChange={(e) => setPays(e.target.value)}
                  value={pays}
                  disabled
                  required
                />
              </div>

              <div className="w-full md:px-4 lg:flex gap-2">
                <div className="w-full">
                  <select
                    name="HeadlineAct"
                    id="HeadlineAct"
                    className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 mb-2"
                    onChange={(e) => setVille(e.target.value)}
                    value={ville}
                  >
                    <option value="">Ville </option>
                    <option value="Bafoussam">Bafoussam</option>
                    <option value="Bamenda">Bamenda</option>
                    <option value="Douala">Douala</option>
                    <option value="Edéa">Edéa</option>
                    <option value="Yaoundé">Yaoundé</option>
                  </select>
                </div>
                <TextField
                  className="mb-2 w-full"
                  label="Quatier"
                  value={quatier}
                  onChange={(e) => setQuatier(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="my-2 sm:p-2 md:p-6 text-start">
            <label className="mb-3 font-medium ml-1 text-xl mx-auto flex justify-center">
              Votre Profil
            </label>

            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  value={selectedValue}
                  onChange={(event, value) => setSelectedValue(value)} // Utilisez l'argument 'value' directement
                  renderInput={(params) => (
                    <TextField {...params} label="Domaine d'activité" />
                  )}
                />
              </Grid>
            </div>
            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  onChange={(event, value) => setSelectedValue_2(value)}
                  value={selectedValue_2}
                  renderInput={(params) => (
                    <TextField {...params} label="Seconde activité" />
                  )}
                />
              </Grid>
            </div>
            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  onChange={(event, value) => setSelectedValue_3(value)}
                  value={selectedValue_3}
                  renderInput={(params) => (
                    <TextField {...params} label="Troisième activité" />
                  )}
                />
              </Grid>
            </div>
            <div>
              <div className="upload-box">
                <div className="w-ful rounded-xl p-4 border-1 border-dashed border-blue-600 flex justify-center items-center">
                  <span
                    className="border-2 rounded-lg text-blue-500 px-4"
                    role="button"
                    onClick={selectFiles}
                  >
                    {" "}
                    Ajouter des images
                  </span>

                  <input
                    type="file"
                    className="file"
                    multiple
                    ref={fileInputRef}
                    onChange={onFileSelect}
                    accept="image/*"
                  />
                </div>

                <div className="w-full h-auto flex justify-start items-start flex-wrap max-h-52 overflow-y-auto mt-2 mb-2">
                  {images.map((images, indexKey) => (
                    <div className="w-32 mx-1 h-32 relative" key={indexKey}>
                      <span
                        onClick={() => deleteImage(indexKey)}
                        className="absolute top-1 right-1 cursor-pointer bg-white rounded-full"
                      >
                        <TiDelete className="delete" size={25} color="red" />
                      </span>
                      <img
                        src={images.url}
                        alt={images.name}
                        className="w-full h-full object-contain rounded-md"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <TextField
                type="texte"
                onChange={(e) => setBiographie(e.target.value)}
                value={biographie}
                label="Description"
                multiline
                rows={6}
                fullWidth
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="my-2 sm:p-2 md:p-6 text-start">
            <div className="w-full">
              <div className="w-full">
                <label className="my-3 font-medium  text-xl">
                  Mot de passe
                </label>
                <div className="relative w-full">
                  <button
                    className="text-gray-400 absolute right-3 inset-y-0 my-auto active:text-gray-600"
                    onClick={() => setPasswordHidden(!isPasswordHidden)}
                  >
                    {isPasswordHidden ? <FaEye /> : <FaEyeSlash />}
                  </button>
                  <input
                    type={isPasswordHidden ? "password" : "text"}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Entrez votre mot de passe"
                    className="w-full pr-12 pl-3 py-3 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <div>
      <div>
        <div className="pt-4 mt-8 max-w-2xl mx-auto">
          <div className="mt-4">
            <Container>
              <label className="mb-3 font-bold text-xl">
                étape {currentStep} sur {totalSteps}{" "}
              </label>
              <div className="mb-3">
                <LinearProgress
                  variant="determinate"
                  value={(activeStep + 1) * 34}
                />
              </div>
              {errorMessage !== "" ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Stack>
              ) : (
                <span></span>
              )}

              <div className="boder-1 rounded-lg shadow-lg p-4 max-w-2xl mx-auto mt-8">
                {activeStep === steps.length ? (
                  <div>
                    <Typography>Toutes les étapes sont complétées</Typography>
                  </div>
                ) : (
                  <div>
                    {getStepContent(activeStep)}
                    <div>
                      {loading ? (
                        <div className="flex mx-4 items-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <div className="flexCenter mt-4">
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Retour
                          </Button>
                          <Button
                            className="bg-blue-700"
                            variant="contained"
                            onClick={
                              activeStep === steps.length - 1
                                ? handleSubmit
                                : handleNext
                            }
                            // color="error"
                          >
                            {activeStep === steps.length - 1
                              ? "Terminer"
                              : "Suivant"}
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <span>
                        J'ai déja un compte :{" "}
                        <a href="/login" className="text-blue-900 font-medium">
                          Me connecter
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Dialog open={isOpen}>
                  <div
                    role="alert"
                    className="rounded-xl border border-gray-100 bg-white p-4"
                  >
                    <div className="flex items-start gap-4">
                      {success ? (
                        <span className="text-green-600">
                          <FaCheck size={35} color="green" />
                        </span>
                      ) : (
                        <span className="text-red-600">
                          <FaClock size={35} color="red" />
                        </span>
                      )}

                      <div className="flex-1">
                        <strong className="block font-bold">
                          {success ? "success" : "Alert"}{" "}
                        </strong>

                        <p className="mt-1 text-sm text-gray-700">{message}</p>
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      {loading ? (
                        <div className="flex mx-4 items-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <button
                          className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                          onClick={success ? goToLogin : Close}
                        >
                          {success ? "Se connecter" : "fermer"}
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesRegister;

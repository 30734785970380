import React, { useState } from "react";
import "./profilForm.css";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import {
  TextField,
  Grid,
  Autocomplete,
  Stack,
  LinearProgress,
  Button,
  Typography,
  Container,
  CircularProgress,
  Dialog,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../utils/constant_api";
import { Alert } from "@mui/material";
import { FaCheck, FaClock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import { useNavigate } from "react-router-dom";

const steps = [
  "A propos de vous",
  "A propos de votre entreprise",
  "Informations de connexion",
];
const UserRegister = ({ metiers }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isPasswordHidden, setPasswordHidden] = useState(true);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [quatier, setQuatier] = useState("");
  const [pays, setPays] = useState("Cameroun");
  const [ville, setVille] = useState("");
  const [biographie, setBiographie] = useState("");
  const [genre, setGenre] = useState("");
  const [diplome, setDiplome] = useState("");
  const [niveau_exp, setNiveau_exp] = useState("");
  const [langue, setLangue] = useState("");
  const [sec_langue, setSecLangue] = useState("");
  const [competence, setCompetence] = useState("");
  const [marge_sal, setMarge_sal] = useState("");
  const [image, setImage] = useState([]);
  // const [fichier, setFichier] = useState(null);
  const [success, setSuccess] = useState(false);
  // const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [message, setMessage] = useState("");

  const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, i) => currentYear - i
  );

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [dayError, setDayError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [quaterError, setQuaterError] = useState(false);

  const [metierError, setMetierError] = useState(false);
  const [langueError, setLangueError] = useState(false);
  const [biographieError, setBiographieError] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type === "application/pdf") {
      setFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const onFileSelect = (event) => {
    if (window.Android) {
      window.Android.requestStorageAccess();
    }
    setImage(URL.createObjectURL(event.target.files[0]));
    const files = Array.from(event.target.files);
    setMyFiles(files);

    if (files.length === 0) return;

    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
    console.log(images);
  };

  const totalSteps = 3;

  const [isOpen, setIsOpen] = useState(false);
  const Close = () => {
    setIsOpen(false);
  };

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue_2, setSelectedValue_2] = useState(null);
  const [selectedValue_3, setSelectedValue_3] = useState(null);
  const options = metiers?.map((option, i) => {
    const firstLetter = option?.titre[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const validateStep1 = () => {
    let valid = true;

    if (!lastName) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }

    if (!firstName) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }
    if (!phone) {
      setPhoneError(true);
      valid = false;
    } else {
      setPhoneError(false);
    }

    if (!genre) {
      setGenderError(true);
      valid = false;
    } else {
      setGenderError(false);
    }

    if (!day) {
      setDayError(true);
      valid = false;
    } else {
      setDayError(false);
    }

    if (!month) {
      setMonthError(true);
      valid = false;
    } else {
      setMonthError(false);
    }

    if (!year) {
      setBirthdayError(true);
      valid = false;
    } else {
      setBirthdayError(false);
    }

    if (!ville) {
      setCityError(true);
      valid = false;
    } else {
      setCityError(false);
    }

    if (!quatier) {
      setQuaterError(true);
      valid = false;
    } else {
      setQuaterError(false);
    }

    // Ajoutez d'autres validations ici pour les autres champs (e.g. genre, date de naissance, etc.)

    return valid;
  };

  const validateStep2 = () => {
    let valid = true;

    if (!selectedValue) {
      setMetierError(true);
      valid = false;
    } else {
      setMetierError(false);
    }

    if (!langue) {
      setLangueError(true);
      valid = false;
    } else {
      setLangueError(false);
    }

    if (!biographie) {
      setBiographieError(true);
      valid = false;
    } else {
      setBiographieError(false);
    }

    return valid;
  };

  const handleNext = () => {
    if (currentStep === 1) {
      // Vérification pour l'étape 1
      if (validateStep1()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCurrentStep(currentStep + 1);
      } else {
        setIsOpen(true);
        setMessage("Veuillez remplir tous les champs de l'étape 1.");
      }
    } else if (currentStep === 2) {
      // Vérification pour l'étape 2
      if (validateStep2()) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCurrentStep(currentStep + 1);
      } else {
        setIsOpen(true);
        setMessage("Veuillez remplir tous les champs de l'étape 2.");
      }
    }
  };
  

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(currentStep - 1);
  };

  const validateForm = () => {
    return password.length > 0;
  };

  const goToLogin = () => {
    window.location.href = "/login";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateNaissance = `${day}-${month}-${year}`;
    const metier_id = selectedValue?.id;
    const metier_id_2 = selectedValue_2?.id;
    const metier_id_3 = selectedValue_3?.id;

    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();

      // Ajouter dynamiquement uniquement les champs non vides
      const fields = {
        diplome,
        metier_id,
        metier_id_2,
        metier_id_3,
        experience: niveau_exp,
        genre,
        biographie,
        pays,
        ville,
        quatier,
        datenais: dateNaissance,
        langueprincipale: langue,
        languesecondaire: sec_langue,
        lastName,
        firstName,
        phoneNumber: phone,
        password,
        competence,
        cv: file,
      };

      // Ajouter uniquement les champs remplis
      Object.keys(fields).forEach((key) => {
        if (fields[key]) {
          formData.append(key, fields[key]);
        }
      });

      // Ajouter les fichiers uniquement si présents
      if (myFiles && myFiles.length > 0) {
        myFiles.forEach((file) => formData.append("images[]", file));
      }

      axios
        .post(`${baseUrl}/employe/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setMessage('votre compte a été créer avec succès')
            setSuccess(true)
            setLoading(false)
            setIsOpen(true)
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 503) {
            setSuccess(true);
            setMessage(
              `le numéro ${phone} est déja Utilisé veuillez vous connecter et finalisez votre inscription`
            );
            setIsOpen(true);
          } else {
            setError(error.response.data.errors);
            setMessage(error.response.data.errors);
            setIsOpen(true);
          }
        });
    } else {
      setIsOpen(true);
      setMessage("Veuillez saisir votre mot de passe");
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="mb-2 md:mx-auto sm:text-center lg:max-w-2xl md:mb-4 p-2 ">
              <h2 className="font-bold text-blue-600">
                S'ENREGISTRER SUR EMPLOI MONDE
              </h2>
              <span className="text-sm font-semibold text-gray-500">
                CHERCHEUR D'EMPLOI{" "}
              </span>
            </div>
            <label className="mb-4 font-bold flex justify-center">
              À propos de vous
            </label>

            <div className="mb-3 mt-2">
              <div className="w-full lg:flex gap-2 md:px-4">
                <TextField
                  required
                  label="Nom"
                  className={`mb-2 w-full ${
                    lastNameError ? "border-red-600" : ""
                  }`}
                  name="name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  error={lastNameError}
                  helperText={lastNameError ? "Nom est obligatoire" : ""}
                />
                <TextField
                  required
                  label="Prénom"
                  className={`mb-2 w-full ${
                    firstNameError ? "border-red-600" : ""
                  }`}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  error={firstNameError}
                  helperText={firstNameError ? "Prénom est obligatoire" : ""}
                />
              </div>

              {/* Email et téléphone */}
              <div className="w-full md:px-4 lg:flex gap-2">
                <div className="mb-2 w-full">
                  <TextField
                    type="email"
                    className={`w-full`}
                    label="Adresse Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>

                <div className="mb-2 w-full">
                  <TextField
                    className={`w-full ${phoneError ? "border-red-600" : ""}`}
                    label="Numéro de télephone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    required
                    error={phoneError}
                    helperText={phoneError ? "Téléphone est obligatoire" : ""}
                  />
                </div>
              </div>

              <div className="w-full md:px-4 flex gap-2">
                <div className="w-full mt-3">
                  <select
                    name="HeadlineAct"
                    id="HeadlineAct"
                    className={`w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 ${
                      genderError ? "border-red-600 text-red-600" : ""
                    }`}
                    onChange={(e) => setGenre(e.target.value)}
                    value={genre}
                  >
                    <option value="">Sexe </option>
                    <option value="homme">Homme</option>
                    <option value="femm">Femme</option>
                  </select>
                </div>
              </div>

              <label
                htmlFor=""
                className={`flex text-left mx-2 text-lg ${
                  birthdayError ? "text-red-600" : ""
                }`}
              >
                date de naissance
              </label>
              <div className="w-full flex py-2 gap-1">
                <select
                  className={`w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 ${
                    dayError ? "border-red-600 text-red-600" : ""
                  }`}
                  id="day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  required
                >
                  <option value="">Jour</option>
                  {days.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
                <select
                  className={` w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 ${
                    monthError ? "border-red-600 text-red-600" : ""
                  }`}
                  id="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  required
                >
                  <option value="">Mois</option>
                  <option value="Janvier">Janvier</option>
                  <option value="Fevrier">Février</option>
                  <option value="Mars">Mars</option>
                  <option value="Avril">Avril</option>
                  <option value="Mai">Mai</option>
                  <option value="Juin">Juin</option>
                  <option value="Juillet">Juillet</option>
                  <option value="Aout">Aout</option>
                  <option value="Septembre">Septembre</option>
                  <option value="Octobre">Octobre</option>
                  <option value="Novembre">Novembre</option>
                  <option value="Decembre">Decembre</option>
                </select>
                <select
                  className={`w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 ${
                    cityError ? "border-red-600 text-red-600" : ""
                  }`}
                  id="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  required
                >
                  <option value="">année</option>
                  {years.map((y) => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:px-4 flex gap-2">
                <TextField
                  className="mb-2 w-full "
                  label="Pays"
                  onChange={(e) => setPays(e.target.value)}
                  value={pays}
                  disabled
                  required
                />
                <div className="w-full">
                  <select
                    name="HeadlineAct"
                    id="HeadlineAct"
                    className={` w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 ${
                      cityError ? "border-red-600 text-red-600" : ""
                    }`}
                    onChange={(e) => setVille(e.target.value)}
                    value={ville}
                  >
                    <option
                      value=""
                      className={`${cityError ? "text-red-600" : ""}`}
                    >
                      Ville{" "}
                    </option>
                    <option value="Bafoussam">Bafoussam</option>
                    <option value="Bamenda">Bamenda</option>
                    <option value="Douala">Douala</option>
                    <option value="Edéa">Edéa</option>
                    <option value="Yaoundé">Yaoundé</option>
                  </select>
                </div>
              </div>

              <div className="w-full md:px-4">
                <TextField
                  className={`mb-2 w-full ${
                    quaterError ? "border-red-600" : ""
                  }`}
                  label="Quatier ex: bonaberie"
                  value={quatier}
                  onChange={(e) => setQuatier(e.target.value)}
                  required
                  error={quaterError}
                  helperText={quaterError ? "Quatier est obligatoire" : ""}
                />
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="my-2 sm:p-2 md:p-6 text-start">
            <label className="mb-3 font-medium ml-1 text-xl mx-auto flex justify-center">
              Votre Profil
            </label>

            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  value={selectedValue}
                  onChange={(event, value) => setSelectedValue(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Domaine d'activité"
                      error={metierError} // Affiche une erreur si nécessaire
                      helperText={metierError ? "Ce champ est requis" : ""}
                    />
                  )}
                />
              </Grid>
            </div>
            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  onChange={(event, value) => setSelectedValue_2(value)}
                  value={selectedValue_2}
                  renderInput={(params) => (
                    <TextField {...params} label="Autre compétence 1" />
                  )}
                />
              </Grid>
            </div>
            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  onChange={(event, value) => setSelectedValue_3(value)}
                  value={selectedValue_3}
                  renderInput={(params) => (
                    <TextField {...params} label="Autre compétence 2" />
                  )}
                />
              </Grid>
            </div>
            <label htmlFor="" className="text-xs mx-1 font-medium">
              Veuillez saisir Votre metier si vous ne l'avez pas trouvé dans la
              liste
            </label>
            <div className="mb-2">
              <TextField
                name="competence"
                onChange={(e) => setCompetence(e.target.value)}
                value={competence}
                label="Metier"
                className="w-full"
                fullWidth
              />
            </div>
            <div className="w-full gap-2 md:px-4 mb-2 space-y-2">
              <div className="w-full">
                <select
                  name="HeadlineAct"
                  id="HeadlineAct"
                  className={`w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 ${langueError
                    ? 'border-red-600 text-red-600' : ''}`}
                  onChange={(e) => setLangue(e.target.value)}
                  value={langue}
                >
                  <option value="">Langue principale</option>
                  <option value="Français et anglais">
                    Français et Anglais
                  </option>
                  <option value="Français">Français</option>
                  <option value="Anglais">Anglais</option>
                  <option value="Espagnol">Espagnol</option>
                  <option value="Allemand">Allemand</option>
                </select>
              </div>
              <div className="w-full">
                <select
                  name="HeadlineAct"
                  id="HeadlineAct"
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3"
                  onChange={(e) => setSecLangue(e.target.value)}
                  value={sec_langue}
                >
                  <option value="">Autre(s) Langue(s)</option>
                  <option value="Français">Français</option>
                  <option value="Chinois">Chinois</option>
                  <option value="Anglais">Anglais</option>
                  <option value="Espagnol">Espagnol</option>
                  <option value="Allemand">Allemand</option>
                </select>
              </div>
            </div>
            <div className="w-full flex gap-2 md:px-4 mb-2">
              <div className="w-1/2">
                <TextField
                  name="entreprise"
                  onChange={(e) => setDiplome(e.target.value)}
                  value={diplome}
                  label="Dernier Diplome Obtenue"
                  fullWidth
                />
              </div>
              <div className="w-1/2">
                <select
                  name="HeadlineAct"
                  id="HeadlineAct"
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3"
                  onChange={(e) => setNiveau_exp(e.target.value)}
                  value={niveau_exp}
                >
                  <option value="">Année(s) d'experience</option>
                  <option value="Débutant">Débutant</option>
                  <option value="1 an">1 an</option>
                  <option value="2 ans">2 ans</option>
                  <option value="3 ans">3 ans</option>
                  <option value="4 ans">4 ans</option>
                  <option value="5 ans">5 ans</option>
                  <option value="6 ans">6 ans</option>
                  <option value="7 ans">7 ans</option>
                  <option value="8 ans">8 ans</option>
                  <option value="9 ans">9 ans</option>
                  <option value="10 ans">10 ans</option>
                </select>
              </div>
            </div>
            <div className="mb-2">
              <TextField
                type="number"
                onChange={(e) => setMarge_sal(e.target.value)}
                value={marge_sal}
                label="Combien souhaite-vous être payé"
                fullWidth
              />
            </div>
            <div>
              <TextField
                
                // className={biographieError ? 'text-red-600 border-red-600' : ''}
                type="texte"
                onChange={(e) => setBiographie(e.target.value)}
                value={biographie}
                label="Faites une description de vos compétences"
                multiline
                rows={6}
                error = {biographieError}
                fullWidth
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="my-2 sm:p-2 md:p-6 text-start">
            <div className=" border-2 border-blue-600 border-dashed p-2">
              <div className="new-bottomLeft">
                <img src={image} alt="" />
              </div>
              <div className="newFormInput justify-center flex">
                <label
                  htmlFor="file"
                  className="rounded-xl border-2 border-blue-600  px-4 py-1 my-2"
                >
                  Ajoutez une photo de profil{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  ref={fileInputRef}
                  onChange={onFileSelect}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="mt-2">
              <h1 className="font-medium mb-1 text-lg">Ajoutez un CV</h1>
              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                style={{ marginBottom: "20px" }}
              />

              {previewUrl && (
                <div style={{ marginBottom: "20px", height: "500px" }}>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                  >
                    <Viewer fileUrl={previewUrl} />
                  </Worker>
                </div>
              )}
            </div>
            <div className="w-full">
              <div className="w-full">
                <label className="my-3 font-medium  text-xl">
                  Mot de passe
                </label>
                <div className="relative w-full">
                  <button
                    className="text-gray-600 absolute right-3 inset-y-0 my-auto active:text-gray-600"
                    onClick={() => setPasswordHidden(!isPasswordHidden)}
                  >
                    {isPasswordHidden ? <FaEye /> : <FaEyeSlash />}
                  </button>
                  <input
                    type={isPasswordHidden ? "password" : "text"}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Entrez votre mot de passe"
                    className="w-full pr-12 pl-3 py-3 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                  />
                </div>
              </div>
              <label
                htmlFor="condition"
                className="flex cursor-pointer items-start gap-4 mt-2"
              >
                <div className="flex items-center">
                  &#8203;
                  <input
                    type="checkbox"
                    className="size-4 rounded border-gray-300"
                    id="condition"
                  />
                </div>
                <p>
                  J'accepte de passer une période de test en entreprise si mon
                  profil est rétenu
                </p>
              </label>
            </div>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div>
      <div className="pt-4 mt-8 max-w-2xl mx-auto">
        <div className="mt-4">
          <Container>
            <label className="mb-3 font-bold text-xl">
              étape {currentStep} sur {totalSteps}{" "}
            </label>
            <div className="mb-3">
              <LinearProgress
                variant="determinate"
                value={(activeStep + 1) * 34}
              />
            </div>
            {errorMessage !== "" ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{errorMessage}</Alert>
              </Stack>
            ) : (
              <span></span>
            )}

            <div className="boder-1 rounded-lg shadow-lg p-4 max-w-2xl mx-auto mt-8">
              {activeStep === steps.length ? (
                <div>
                  <Typography>Toutes les étapes sont complétées</Typography>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div>
                    {loading ? (
                      <div className="flex mx-4 items-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="flexCenter mt-4">
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Retour
                        </Button>
                        <Button
                          className="bg-blue-700"
                          variant="contained"
                          onClick={
                            activeStep === steps.length - 1
                              ? handleSubmit
                              : handleNext
                          }
                          // color="error"
                        >
                          {activeStep === steps.length - 1
                            ? "Terminer"
                            : "Suivant"}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <span>
                      J'ai déja un compte :{" "}
                      <a href="/login" className="text-blue-900 font-medium">
                        Me connecter
                      </a>{" "}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div>
              <Dialog open={isOpen}>
                <div
                  role="alert"
                  className="rounded-xl border border-gray-100 bg-white p-4"
                >
                  <div className="flex items-start gap-4">
                    {success ? (
                      <span className="text-green-600">
                        <FaCheck size={35} color="green" />
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <FaClock size={35} color="red" />
                      </span>
                    )}

                    <div className="flex-1">
                      <strong className="block font-bold text-xl">
                        {" "}
                        {success ? "success" : "Alert"}{" "}
                      </strong>

                      <p className="mt-1 text-sm text-gray-700">{message}</p>
                    </div>
                  </div>

                  <div className="flex justify-center mt-4">
                    {loading ? (
                      <div className="flex mx-4 items-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                        onClick={success ? goToLogin : Close}
                      >
                        {success ? "Se connecter" : "fermer"}
                      </button>
                    )}
                  </div>
                </div>
              </Dialog>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default UserRegister;

import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../utils/constant_api";
// import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const [step, setStep] = useState(1); // Étape 1: Entrée du numéro de téléphone
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();

  const handleSendCode = () => {
    if (!phone) {
      setError("Veuillez entrer un numéro de téléphone.");
      return;
    }
    setLoading(true);

    axios
      .post(`${baseUrl}/send-otp`, { phoneNumber: phone })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          setStep(2);
          setError("");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(error?.response?.data?.errors);
      });
  };

  const handleVerifyCode = () => {
    setLoading(true);
    axios
      .post(`${baseUrl}/verify-otp`, { phoneNumber: phone, otp: code })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          setStep(3);
          setError("");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error?.response?.data?.message);
      });
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    } 

    axios
      .post(`${baseUrl}/reset/password`, { phoneNumber: phone, new_password:newPassword })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
            
            const formData = new FormData();
            formData.append("email", phone);
            formData.append("password", newPassword);
             axios
             .post(`${baseUrl}/auth/login`, formData)
             .then(async (res) => {
                if (res.status === 200 || res.status === 201) {
                    localStorage.setItem("token", res.data.access_token);
                    const token = localStorage.getItem("token");
            
                    // Récupération des informations utilisateur-
                    const response = await axios.get(`${baseUrl}/auth/me`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    });

                    console.log(response)
            
                    if (response.status === 200 || response.status === 201) {
                      localStorage.setItem("userRole", response.data.type);
                      // Redirection selon le rôle de l'utilisateur
                      if (response.data.type === "admin") {
                        window.location.href = "/admin";
                      } else {
                        console.log('object')
                        window.location.href = "/profil-page";
                      }
                    }
                  }
             })
          
    
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error?.response?.data?.message);
      });



    // Appeler une API pour mettre à jour le mot de passe
    console.log("Nouveau mot de passe:", newPassword);
    setError("");
    // alert("Mot de passe réinitialisé avec succès !")
  };

  return (
    <div className="paddings w-full max-w-xl shadow-lg border rounded-lg my-24 gap-4 mx-4 mx-auto">
      <div className="mx-auto rounded-full border shadow-lg w-32 h-32 items-center flex">
        <img
          src="images/emploi-monde.png"
          alt="logo"
          className="object-cover w-full h-full"
        />
      </div>
      {step === 1 && (
        <div>
          <h2 className="font-medium text-2xl">
            Réinitialiser le mot de passe
          </h2>
          <p className="text-lg font-medium text-gray-400">
            Entrez votre numéro de téléphone pour recevoir un code de
            réinitialisation :
          </p>
          <input
            className="w-full p-2 outline-none border-1 mt-4"
            type="text"
            placeholder="Numéro de téléphone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button
              onClick={handleSendCode}
              className="bg-blue-600 text-white rounded-md mt-3 py-2 w-full my-2"
            >
              Envoyer le code
            </button>
          )}
        </div>
      )}

      {step === 2 && (
        <div>
          <h2 className="font-medium text-2xl">Confirmer le code</h2>
          <p className="font-medium">Entrez le code que vous avez reçu au numéro : {phone}</p>
          <input
            className="w-full p-2 outline-none border-1 mt-4"
            type="text"
            placeholder="Code de vérification"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button
              onClick={handleVerifyCode}
              className="bg-blue-600 text-white rounded-md mt-3 py-2 w-full my-2"
            >
              Vérifier
            </button>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="">
          <h2 className="font-medium text-2xl">
            Réinitialiser le mot de passe
          </h2>
          <input
            className="w-full p-2 outline-none border-1 mt-4"
            type="password"
            placeholder="Nouveau mot de passe"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            className="w-full p-2 outline-none border-1 mt-2"
            placeholder="Confirmer le mot de passe"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button
              onClick={() => handleResetPassword()}
              className="bg-blue-600 text-white rounded-md mt-3 py-2 w-full my-2"
            >
              Réinitialiser le mot de passe
            </button>
          )}
        </div>
      )}

      {error && (
        <p
          style={{ color: "red" }}
          className="text-lg bg-red-200 rounded-md p-2 mt-2"
        >
          {error}
        </p>
      )}
    </div>
  );
}

export default ResetPassword;

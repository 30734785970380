import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../utils/constant_api";

const AdminTravelPage = () => {
  const [agences, setAgences] = useState([]); 


  useEffect(() => {
    axios.get(`${baseUrl}/agences`)
    .then((res) => {
      setAgences(res?.data)
    }).catch((error) => {
      console.log(error)
      alert('une erreur est survenu')
    })

  }, [])
  return (
    <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

<div className="grid gap-10 mx-auto lg:max-w-screen-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
        {agences.map((candidat) => (
          <div
            className="flex flex-col items-center cursor-pointer"
            key={candidat?.id}
            // onClick={() => ProfileDetail(candidat?)}
          >
            <img
              className="object-cover w-20 h-20 mb-2 rounded-full shadow"
              src={candidat?.images[0]}
              alt="Person"
            />
            <div className="flex flex-col items-center">
              <p className="text-lg font-bold">
                {candidat?.lastName}{" "}
              </p>
              <p className="text-sm text-gray-800">
              {candidat?.firstName} {""}
              </p>
            </div>
          </div>
        ))}
      </div>


    </div>
  );
};

export default AdminTravelPage;

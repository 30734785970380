import React from "react";
import { useState } from "react";
import { TextField, Grid, Autocomplete, Dialog } from "@mui/material";
import { useEffect } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";

const AdminTechnicien = ({ metiers, profiles }) => {
  const [candidats, setCandidats] = useState([]);
  const [categorieSelectionnee, setCategorieSelectionnee] = useState("tous");
  // const [selectedValue, setSelectedValue] = useState(null);
  const [profil, setProfil] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  const ProfileDetail = (candidat) => {
    console.log(candidat);
    setProfil(candidat);
    setIsOpen(true);
  };

  const token = localStorage.getItem('token')


  const deleteProfil = (id) => {
    axios.delete(`${baseUrl}/auth/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
      if(res.status === 200 || res.status === 201) {
        window.location.reload()
      }
    }).catch((error) => {
      alert('une erreur est survenue')
      console.log(error)    
    })
  };

  const handleChange = (event, value) => {
    // setSelectedValue(value);
    setCategorieSelectionnee(value?.titre);
  };

  const options = metiers?.map((option, i) => {
    const firstLetter = option?.titre[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  

  useEffect(() => {
    if (
      categorieSelectionnee === "tous" ||
      categorieSelectionnee === undefined
    ) {
      setCandidats(
        profiles.filter(
          (profile) =>
            profile?.type === "technicien" || profile?.type === "prestataire"
        )
      );
    } else {
      setCandidats(
        profiles.filter(
          (profile) =>
            profile?.type === "technicien" &&
            profile?.profil?.metier?.titre === categorieSelectionnee
        )
      );
    }
  }, [profiles, categorieSelectionnee]);
  return (
    <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
          Liste des Techniciens present sur la plate forme
        </p>
        <Grid item xs={12}>
          <Autocomplete
            id="metier"
            isOptionEqualToValue={(option, value) => option.id === value.id} // Comparez uniquement les IDs
            options={options.sort(
              (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
            )}
            getOptionLabel={(option) => option.titre}
            name="metier"
            onChange={handleChange} // Utilisez l'argument 'value' directement
            renderInput={(params) => (
              <TextField {...params} label="Profil recherché" />
            )}
          />
        </Grid>
      </div>

      <div>
        <Dialog open={isOpen}>
          <div
            role="alert"
            className="rounded-xl border border-gray-100 bg-white p-4"
          >
            <div className="relative block overflow-hidden rounded-lg border w-full border-gray-100 p-4 sm:p-6 lg:p-8 max-w-lg mx-2 mt-2">
              <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

              <div
                className="bg-red-300 w-full rounded-md text-center items-center justify-center cursor-pointer p-2"
                onClick={() => deleteProfil(profil.id)}
              >
                Supprimer le profil
              </div>

              <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
                <span className="font-bold">
                  {" "}
                  {profil?.firstName} {profil?.lastName}{" "}
                </span>
              </div>
              <div className="my-4">
                <div className="w-36 h-36 mx-auto">
                  <img
                    className="rounded-full object-cover w-full h-full shadow-lg"
                    alt=""
                    src={profil.images?.length > 0 ? profil?.images[0] : profil}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4">
                <div className="w-full">
                  <div className="flex  items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Langue : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {profil?.profil?.langueprincipale}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Diplome : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {profil?.profil?.diplome}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Metier : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {profil?.profil?.metier?.titre}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Residence : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {profil?.profil?.ville}, {profil?.profil?.pays}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center ">
                    <div className="w-1/3 flex items-start justify-start">
                      <span className="text-sm font-medium">Naisssance : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {profil?.profil?.datenais}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center ">
                    <div className="w-1/3 flex items-start justify-start text-blue-600">
                      <span className="text-sm font-medium">Télephone : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2  text-blue-600">
                        {profil?.phoneNumber}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center ">
                    <div className="w-1/3 flex items-start justify-start">
                      <span className="text-sm font-medium">Statut : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2 mt-1">
                        {profil.is_premium ? (
                          <div className="flex items-center gap-2">
                            <FaCheck size="20" color="green" />{" "}
                            <span>Actif</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <RiCloseCircleFill size="20" color="red" />{" "}
                            <span>inactif</span>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <p className="text-pretty text-sm text-gray-600 text-justify">
                  {profil?.profil?.biographie}
                </p>
              </div>

              
            </div>

            <div className="flex justify-center mt-4">
              <button
                className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                onClick={close}
              >
                fermer
              </button>
            </div>
          </div>
        </Dialog>
      </div>

      <div className="grid gap-10 mx-auto lg:max-w-screen-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
        {candidats.map((candidat) => (
          <div
            className="flex flex-col items-center cursor-pointer"
            key={candidat.id}
            onClick={() => ProfileDetail(candidat)}
          >
            <img
              className="object-cover w-20 h-20 mb-2 rounded-full shadow"
              src={candidat.images[0]}
              alt="Person"
            />
            <div className="flex flex-col items-center">
              <p className="text-lg font-bold">
                {candidat.firstName} {""} {candidat.lastName}{" "}
              </p>
              <p className="text-sm text-gray-800">
                {candidat?.profil?.metier?.titre}
              </p>
              <span
                className={
                  candidat?.is_premium
                    ? "bg-green-500 px-4 rounded text-white"
                    : "bg-red-500 px-4 rounded text-white"
                }
              >
                {candidat?.is_premium ? "Actif" : "Inactif"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminTechnicien;

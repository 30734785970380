import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Pack = ({ authUser, plans }) => {
  const [packs, setPacks] = useState([]);

  console.log(plans);
  console.log(authUser);

  const navigate = useNavigate();
  const goToPayment = (plan) => {
    navigate(`plan/${plan.nom}/${plan.id}`);
  };

  useEffect(() => {
    if (
      (authUser?.type === "technicien" || authUser?.type === "prestataire") &&
      authUser?.isInscrit === true
    ) {
      setPacks(plans?.filter((plan) => plan?.type === "technicien"));
    } else if (authUser?.type === "recruteur") {
      setPacks(plans?.filter((plan) => plan?.type === "recruteur"));
    } else if (
      (authUser?.isInscrit === false) &&
      (authUser?.type === "technicien" ||
        authUser?.type === "prestataire" ||
        authUser?.type === "employe")
    ) {
      setPacks(plans?.filter((plan) => (plan?.type === "inscription" || plan?.type === null)));
    } else if (authUser?.isInscrit === true && authUser?.type === "employe") {
      setPacks(plans?.filter((plan) => plan?.type === "user"));
    }
  }, [authUser, plans]);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <div className="max-w-screen-xl py-20">
      <h1 className="font-bold text-lg">Veuillez souscrire à un Abonnement</h1>
      <h1 className="font-semibold text-sm text-gray-500">
        Selectionnez l'abonnement qui correspond à votre compte
      </h1>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8 mt-8 ">
        {packs?.map((plan, i) => (
          <div
            className="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm"
            key={i}
          >
            <div className="p-6 sm:px-8">
              <h2 className="text-xl font-medium text-blue-600 border-1 p-1 border-blue-600 my-2">
                {plan.nom}
              </h2>

              <p className="mt-4 font-medium text-gray-500 text-justify">
                {getText(plan.description)}
              </p>

              <p className="mt-2 sm:mt-4">
                <strong className="text-3xl font-bold text-gray-900 sm:text-4xl">
                  {" "}
                  {plan.prix.split(".")[0]} FCFA
                </strong>

                <span className="text-sm font-medium text-gray-700">
                  {" "}
                  {plan.type === "inscription" ? "" : "/Mois"}{" "}
                </span>
              </p>

              <p
                className="mt-4 block rounded border border-indigo-600 bg-blue-600 px-12 py-3 text-center text-base font-medium text-white hover:bg-blue-800 hover:text-indigo-600 sm:mt-6 cursor-pointer"
                onClick={() => goToPayment(plan)}
              >
                souscrire
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pack;

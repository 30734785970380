/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import AlertEMplyers from "./AlertEMplyers";
import { useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  TextField,
} from "@mui/material";
import profil from "../../Assets/user.png";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { baseUrl } from "../../utils/constant_api";

// const defaultLayoutPluginInstance = defaultLayoutPlugin();

const EmployersClient = ({ authUser, metiers, profiles }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [categorieSelectionnee, setCategorieSelectionnee] = useState("tous");
  const [searchText, setSearchText] = useState(""); // State for search term
  const [candidats, setCandidats] = useState([]);
  const conctat = +237698427176;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [inputValue, setInputValue] = useState("");
  const [filteredCandidats, setFilteredCandidats] = useState([]);



  const Close = () => {
    setIsOpen(false);
  };

  const handleChange = (event, value) => {
    setSelectedValue(value);
    setCategorieSelectionnee(value?.titre);
  };

  const options = metiers?.map((option, i) => {
    const firstLetter = option?.titre[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const [isSearching, setIsSearching] = useState(true);

  const handleDownloadCV = (candidat) => {
    if (candidat && candidat.id) {
      // Lancer le téléchargement en appelant l'API Laravel qui force le téléchargement
      window.location.href = `${baseUrl}/download-cv/${candidat.id}`;
    } else {
      alert("CV non disponible");
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  
    // Fonction pour supprimer les accents
    const removeAccents = (str) => {
      return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
  
    // Filtre les candidats en fonction de la saisie
    const filtered = candidats?.filter((candidat) =>
      removeAccents(candidat?.profil?.competence?.toLowerCase())?.includes(removeAccents(newInputValue.toLowerCase()))
    );
  
    setCandidats(filtered);
  };
  

  const handleSearchChange = (event) => {
    const newSearchText = event.target.value.toLowerCase();
    setSearchText(newSearchText);

    const filteredList = candidats.filter((candidat) =>
      candidat.profil?.quatier?.toLowerCase().includes(newSearchText)
    );

    // Mettre à jour la liste filtrée et l'état de la recherche
    setCandidats(filteredList);
    setIsSearching(!!newSearchText);
  };

  useEffect(() => {
    const loadData = async () => {
      if (
        categorieSelectionnee === "tous" ||
        categorieSelectionnee === undefined
      ) {
        setCandidats(profiles.filter((profile) => profile.type === "employe"));
      } else {
        setCandidats(
          profiles.filter(
            (profile) =>
              (profile.type === "employe" &&
                profile.profil?.metier.titre === categorieSelectionnee) ||
              profile.profil?.metier2?.titre === categorieSelectionnee ||
              profile.profil?.metier3?.titre === categorieSelectionnee
          )
        );
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsLoading(false);
    };
    loadData();
  }, [profiles, categorieSelectionnee]);
  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <CircularProgress size={70} />
        </div>
      ) : (
        <div className="mt-8 py-16">
          {(authUser && authUser.type === "recruteur" && authUser.is_premium === true) ? (
            <div>
              <div className="items-center">
                <div className="mx-auto max-w-xl w-full gap-2 space-y-4 mb-2">
                  <Autocomplete
                    className="mx-2"
                    id="metier"
                    options={options.sort(
                      (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                    )}
                    getOptionLabel={(option) => option.titre}
                    name="metier"
                    onChange={handleChange}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Domaine d'activité" />
                    )}
                  />
                </div>

                <div className="w-full max-w-xl mx-auto">
                  <input
                    className="w-full p-3 border mx-2 rounded-md"
                    type="text"
                    placeholder="Rechercher par quatier"
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>

              <div>
                <Dialog open={isOpen}>
                  <div
                    role="alert"
                    className="rounded-xl border border-gray-100 bg-white p-4"
                  >
                    <div className="flex items-start gap-4">
                      <span className="text-green-600">
                        <FaCheckCircle size={35} color="green" />
                      </span>

                      <div className="flex-1">
                        <strong className="block font-bold"> Succèss </strong>

                        <p className="mt-1 text-sm text-gray-700">
                          Emploi Monde vient d'être informé de votre intérêt
                          pour ce candidat et vous contactera sous peu.
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <button
                        className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                        onClick={Close}
                      >
                        Fermer
                      </button>
                    </div>
                  </div>
                </Dialog>
              </div>

              {candidats.map((candidat, i) => (
                <div
                  className="relative block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 max-w-xl mx-auto mt-2 mb-4"
                  key={i}
                >
                  <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

                  <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
                    <span className="font-bold uppercase">
                      {candidat?.firstName} {candidat?.lastName}{" "}
                    </span>
                  </div>
                  <div className="gap-4">
                    <div className="w-40 h-40 flex mx-auto my-2">
                      <img
                        className="rounded-full object-cover w-full h-full"
                        alt=""
                        src={
                          candidat.images?.length > 0
                            ? candidat?.images[0]
                            : profil
                        }
                      />
                    </div>
                    <div className="w-full pt-3">
                      <div className="flex  items-center">
                        <div className="w-1/3 flex">
                          <span className="text-sm font-medium">Langue : </span>
                        </div>
                        <div className="w-2/3 flex">
                          <span className="text-sm font-bold mx-2">
                            {candidat?.profil?.langueprincipale}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/3 flex">
                          <span className="text-sm font-medium">
                            Diplome :{" "}
                          </span>
                        </div>
                        <div className="w-2/3 flex">
                          <span className="text-sm font-bold mx-2 text-left">
                            {candidat?.profil?.diplome}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className="w-1/3 flex">
                          <span className="text-sm font-medium">Metier : </span>
                        </div>
                        <div className="w-2/3 flex">
                          <span className="text-sm font-bold text-blue-600 mx-2">
                            {candidat?.profil?.metier?.titre}
                          </span>
                        </div>
                      </div>

                      {candidat?.profil?.metier2 && (
                        <div className="flex items-center">
                          <div className="w-1/3 flex">
                            <span className="text-sm font-medium w-full text-left">
                              Compétences 2:{" "}
                            </span>
                          </div>
                          <div className="w-2/3 flex">
                            <span className="text-sm font-bold mx-2 w-full text-left text-blue-600">
                              {candidat?.profil?.metier2?.titre},
                            </span>
                          </div>
                        </div>
                      )}

                      {candidat?.profil?.metier3 && (
                        <div className="flex items-center">
                          <div className="w-1/3 flex">
                            <span className="text-sm font-medium w-full text-left">
                              Compétences 3:{" "}
                            </span>
                          </div>
                          <div className="w-2/3 flex">
                            <span className="text-sm font-bold mx-2 w-full text-left text-blue-600">
                              {candidat?.profil?.metier3?.titre}
                            </span>
                          </div>
                        </div>
                      )}

                      {candidat?.profil?.competence && (
                        <div className="flex items-center">
                          <div className="w-1/3 flex">
                            <span className="text-sm font-medium w-full text-left">
                              Autres competence:{" "}
                            </span>
                          </div>
                          <div className="w-2/3 flex">
                            <span className="text-sm font-bold mx-2 w-full text-left text-blue-600">
                              {candidat?.profil?.competence}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="flex items-center">
                        <div className="w-1/3 flex">
                          <span className="text-sm font-medium">
                            Residence :{" "}
                          </span>
                        </div>
                        <div className="w-2/3 flex">
                          <span className="text-sm font-bold mx-2">
                            {candidat?.profil?.quatier},{" "}
                            {candidat?.profil?.ville}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center ">
                        <div className="w-1/3 flex items-start justify-start">
                          <span className="text-sm font-medium">
                            Naisssance :{" "}
                          </span>
                        </div>
                        <div className="w-2/3 flex">
                          <span className="text-sm font-bold mx-2">
                            {candidat?.profil?.datenais}
                          </span>
                        </div>
                      </div>

                      {candidat.cv !== null && candidat.cv !== undefined && (
                        <div className="flex items-center mt-2">
                          <div className="w-1/3 flex items-start justify-start">
                            <span className="text-sm font-bold">CV : </span>
                          </div>
                          <div className="">
                            <span
                              onClick={() => handleDownloadCV(candidat)}
                              className="font-semibold text-blue-600 mx-2 border-1 border-red-500 px-4 cursor-pointer"
                            >
                              Télécharger le CV
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="text-pretty text-sm text-gray-600 text-justify">
                      {candidat?.profil?.biographie}
                    </p>
                  </div>

                  <div className="mt-6 flex gap-4 sm:gap-6 items-center justify-between">
                    <div className="flex flex-col-reverse">
                      <dt className="text-sm font-medium text-gray-600">
                        {candidat?.profil?.updated_at.split("T")[0]}
                      </dt>
                      <dd className="text-xs text-gray-500">
                        Denière mise à jour
                      </dd>
                    </div>
                    <div>
                      <Link
                        className="border-1 rounded-lg text-blue-600 cursor-pointer px-4 border-blue-600"
                        to={`https://wa.me/${conctat}?text=Bonjour Emploi Monde Je viens de vous trouver sur la plateforme le profil de  ${
                          candidat.firstName + " " + candidat.lastName
                        } qui a un profile  de ${
                          candidat?.profil?.metier?.titre
                        },  Pouvez-vous me fournir plus d'informations sà son sujet ?`}
                        target="_blank"
                      >
                        en savoir plus
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

              <button
                onClick={() => window.location.reload()}
                className="my-4 border bg-blue-600 px-4 text-white rounded-md py-2"
              >
                Rafraichir
              </button>
            </div>
          ) : (
            <div>
              <AlertEMplyers authUser = {authUser}/>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EmployersClient;

import React from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from 'react-router-dom';

const AlertEMplyers = () => {
  return (
    <div className="max-w-5xl mx-auto px-4 md:px-8">
            <div className="flex justify-center p-2 rounded-md bg-red-50 border border-red-300 flex-col">
                <div className="flex gap-1 sm:items-center mx-auto mb-2">
                    <div>
                        <IoIosCloseCircleOutline className='h-6 w-6 text-red-500'/>
                    </div>
                    <p className="text-red-600">
                    connectez vous compte recruteur et payer un abonnement pour avoir accès au profil
                    des canditats.
                    </p>
                </div>

                <div className='flex items-center justify-center flex-col gap-2 lg:w-1/3 mx-auto mt-2'>
                    <Link to = '/recruteur-register'
                        className='border-1 px-4 py-1 rounded-xl text-blue-600 border-red-500 cursor-pointer w-full'>
                        Créer un compte recruteur
                    </Link>
                    <Link to = '/login' 
                        className='border-1 px-4 py-1 rounded-xl text-blue-600 border-red-500 cursor-pointer w-full'>
                        Se connecter
                    </Link>

                </div>
            </div>
        </div>
  )
}

export default AlertEMplyers
